@media (max-width: 768px) {
  .my-hidden {
    display: none;
  }
}
@media (min-width: 768px) {
  .my-hidden {
    display: flex;
  }
}
/* width */
::-webkit-scrollbar {
  width: 13px;
  height: 10px;
  padding: 5px;
  background: gainsboro;
  position: absolute;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin: 15px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  margin: 15px;
  padding: 10px;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
.tooltip {
  position: relative;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -50px;
  left: -50%;
  margin: auto;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.c {
  max-width: 1024px !important;
}

.glass {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
